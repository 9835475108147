import { browser } from '$app/environment';
import { derived, writable } from 'svelte/store';

// Used to store in localStorage.
const light = 'light';
const dark = 'dark';
type Theme = typeof light | typeof dark;

const { subscribe, set, update } = writable<Theme>();

export const theme = {
	subscribe,
	init() {
		// Make it SSR-safe.
		if (!browser) return;

		// This doesn't need to be validated because happens right after
		// hooks.server.ts
		const value = localStorage.getItem('theme') as Theme;
		apply(value);
		set(value);
	},
	set(value: Theme): void {
		apply(value);
		set(value);
	},
	toggle(): void {
		update((value) => {
			switch (value) {
				case light:
					apply(dark);
					return dark;
				case dark:
					apply(light);
					return light;
			}
		});
	}
};

// Actual name of the DaisyUI theme.
export const map = {
	light: 'cupcake',
	dark: 'night'
};
function apply(value: Theme) {
	document.documentElement.setAttribute('data-theme', map[value]);
	localStorage.setItem('theme', value);
}

export const isDark = derived(theme, ($theme) => $theme == dark);
